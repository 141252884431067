<template>
    <layout-main>
        <div class="home">
			<!-- Header -->
			<div class="subheader">
				<div class="subheader-main">
                    <div class="subheader-left">
                        <h3>
                            {{ $t('labels.statistics-combo') }}
                        </h3>
                        <span class="separator-line"></span>
                        <div class="breadcrumbs">
                            <router-link to="/offices" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
                            <span class="separator-dot"></span>
                            <router-link to="/offices/statistics" class="breadcrumbs-link"> {{ $t('labels.statistics-combo') }}</router-link>
                        </div>
                    </div>

                    <div class="subheader-right">
                        <v-guard :permissions="['offices-statistics-export']">
                        <b-dropdown v-if="!isDownloading" id="dropdown-1">
                            <template v-slot:button-content><i class="fas fa-download"></i> {{ $t('labels.export') }}</template>

                            <b-dropdown-group id="dropdown-group-1" header="Format">
                                <b-dropdown-item-button @click="exportResultsEstimate('tickets', 'excel')">Excel
                                </b-dropdown-item-button>
                            </b-dropdown-group>
                        </b-dropdown>

                        <button v-if="isDownloading" class="btn btn-success"><i class="fas fa-circle-notch fa-spin"></i> {{ $t('labels.is-downloading') }}
                        </button>
                        </v-guard>
                    </div>
                </div>
			</div>


            <reactive-base
                app="ticket_index,witness_person"
                :credentials="user.user.es_username + ':' + user.user.es_password"
                :url="elasticUrl"
            >
            <export-tickets
                @beginTicketExport="exportResults"
                @closed="closeExportTickets"
                :exportEstimate="exportEstimate"
                title="Export av statistik AD"
                v-if="exportEstimate"
            >
            </export-tickets>
            <div class="row">
                <div class="col-12">
                <div class="widget">
                    <div class="widget-header statistics-filter">
                    <div class="widget-header-label">
                        <h3>{{ $t('labels.selected-filters') }}</h3>
                    </div>
                    <div class="widget-header-toolbar">
                        <div class="actions">
                        <SelectedFilters class="bb-1 curent-filters pl-4 m-0" :clearAllLabel="this.$t('labels.clear')"/>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <v-guard :permissions="['court-page-statistics-use-filter']">
                <div class="col-12">
                    <div class="widget">
                    <div class="widget-header statistics-filter">
                        <multi-list
                            componentId="yearFilter"
                            :filterLabel="this.$t('labels.year')"
                            :title="this.$t('labels.year')"
                            dataField="year.raw"
                            className="horizontal-filter"
                            sortBy="asc"
                            :showSearch="false"
                            :URLParams="true"
                            :react="{ and: getOtherFilterIds('yearFilter') }"
                            @valueChange="setSelectedYears"
                            :transformData="customYears"
                        >
                        <template slot="renderItem" slot-scope="{ label }">
                            <div>{{ label }}</div>
                        </template>
                        </multi-list>
                        <multi-list
                            componentId="quarterFilter"
                            :filterLabel="this.$t('labels.quarter')"
                            :title="this.$t('labels.quarter')"
                            dataField="quarter.raw"
                            className="horizontal-filter"
                            sortBy="asc"
                            :showSearch="false"
                            :URLParams="true"
                            :react="{ and: getOtherFilterIds('quarterFilter') }"
                        >
                        <template slot="renderItem" slot-scope="{ label }">
                            <div>
                            {{ label }}
                            </div>
                        </template>
                        </multi-list>
                        <multi-list
                            componentId="monthFilter"
                            :filterLabel="this.$t('labels.month')"
                            :title="this.$t('labels.month')"
                            dataField="month.raw"
                            className="horizontal-filter"
                            sortBy="asc"
                            :size="12"
                            :showSearch="false"
                            :URLParams="true"
                            :transformData="sortMonthByLabel"
                            :react="{ and: getOtherFilterIds('monthFilter') }"
                        >
                        <template slot="renderItem" slot-scope="{ label, count }">
                            <div>
                            {{ renameMonthsLabel(label) }}
                            </div>
                        </template>
                        </multi-list
                        >
                    </div>
                    </div>
                </div>
                <div class="col-lg-12 col-xl-3 order-lg-1 order-xl-1">
                    <div class="widget widget-facets statistics-vertical">
                    <div class="widget-header mb-3">
                        <div class="widget-header-label">
                        <h3><i class="fas fa-circle-notch fa-spin" v-show="isLoading"></i>{{ $t('labels.filtration') }}</h3>
                        </div>
                        <div class="widget-header-toolbar">
                        <div class="actions"></div>
                        </div>
                    </div>
                    <div class="widget-body p-0">
                        <multi-dropdown-list
                            className="filter-vertical"
                            :URLParams="true"
                            :showSearch="true"
                            :placeholder="this.$t('labels.select')"
                            sortBy="asc"
                            componentId="genderFilter"
                            :filterLabel="this.$t('labels.gender')"
                            :title="this.$t('labels.gender')"
                            :dataField="'gender.raw'"
                            :react="{ and: getOtherFilterIds('genderFilter') }"
                        />
                    </div>
                    <div class="widget-body p-0">
                        <multi-dropdown-list
                            className="filter-vertical"
                            :URLParams="true"
                            :showSearch="true"
                            :placeholder="this.$t('labels.select')"
                            sortBy="asc"
                            componentId="agesFilter"
                            :filterLabel="this.$t('labels.age-group')"
                            :title="this.$t('labels.age-group')"
                            :dataField="'age.raw'"
                            :transformData="customSortAge"
                            :react="{ and: getOtherFilterIds('agesFilter') }"
                        />
                    </div>

                    <div class="widget-body p-0">
                        <multi-dropdown-list
                            className="filter-vertical"
                            :URLParams="true"
                            :showSearch="true"
                            :placeholder="this.$t('labels.select')"
                            sortBy="asc"
                            componentId="categoryFilter"
                            :size="300"
                            title="Kategori"
                            filterLabel="Kategori"
                            dataField="ad_category.keyword"
                            :react="{ and: getOtherFilterIds('categoryFilter') }"
                        />
                    </div>
                    <div class="widget-body p-0">
                        <multi-dropdown-list
                            className="filter-vertical"
                            :URLParams="true"
                            :showSearch="true"
                            :placeholder="this.$t('labels.select')"
                            sortBy="asc"
                            componentId="crimesFilter"
                            :size="300"
                            :title="this.$t('labels.crime')"
                            :filterLabel="this.$t('labels.crime')"
                            dataField="ad_crime.keyword"
                            :react="{ and: getOtherFilterIds('crimesFilter') }"
                        />
                    </div>
                    <div class="widget-body p-0">
                        <multi-dropdown-list
                            className="filter-vertical"
                            :URLParams="true"
                            :showSearch="true"
                            :placeholder="this.$t('labels.select')"
                            sortBy="asc"
                            :title="this.$t('labels.special-circumstances')"
                            componentId="specialCircumstanceFilter"
                            :filterLabel="this.$t('labels.special-circumstances')"
                            nestedField="crime_relation"
                            :dataField="'crime_relation.label.raw'"
                            :react="{ and: getOtherFilterIds('specialCircumstanceFilter') }"
                        />
                    </div>
                    <div class="widget-body p-0">
                        <multi-dropdown-list
                            className="filter-vertical"
                            :URLParams="true"
                            :showSearch="true"
                            :placeholder="this.$t('labels.select')"
                            sortBy="asc"
                            :title="this.$t('labels.special-events')"
                            componentId="specialEventFilter"
                            :filterLabel="this.$t('labels.special-events')"
                            :dataField="'special_event.raw'"
                            :react="{ and: getOtherFilterIds('specialEventFilter') }"
                        />
                    </div>

                    <div class="widget-body p-0">
                        <multi-dropdown-list
                            className="filter-vertical"
                            :URLParams="true"
                            :showSearch="true"
                            :placeholder="this.$t('labels.select')"
                            sortBy="asc"
                            :size="100"
                            componentId="officeFilter"
                            :title="this.$t('labels.victim-support-centre')"
                            :filterLabel="this.$t('labels.victim-support-centre')"
                            dataField="office.raw"
                            :react="{ and: getOtherFilterIds('officeFilter') }"
                        />
                    </div>
                    <div class="widget-body p-0">
                        <multi-dropdown-list
                            className="filter-vertical"
                            :URLParams="true"
                            :showSearch="true"
                            :placeholder="this.$t('labels.select')"
                            sortBy="asc"
                            title="Stödinsats"
                            componentId="testimonySupportFilter"
                            filterLabel="Stödinsats"
                            nestedField="testimony_support"
                            dataField="testimony_support.label.raw"
                            :react="{ and: getOtherFilterIds('testimonySupportFilter') }"
                        />
                    </div>
                    <div class="widget-body p-0">
                        <multi-dropdown-list
                            className="filter-vertical"
                            :URLParams="true"
                            :showSearch="true"
                            :placeholder="this.$t('labels.select')"
                            sortBy="asc"
                            :size="100"
                            componentId="courtFilter"
                            filterLabel="Domstol"
                            title="Domstol"
                            dataField="court.raw"
                            :react="{ and: getOtherFilterIds('courtFilter') }"
                        />
                    </div>
                    <div class="widget-body p-0">
                        <multi-dropdown-list
                            className="filter-vertical"
                            :URLParams="true"
                            :showSearch="true"
                            :placeholder="this.$t('labels.select')"
                            sortBy="asc"
                            componentId="typeOfTicketFilter"
                            :size="300"
                            title="Statistiktabell"
                            filterLabel="Statistiktabell"
                            dataField="ad_type_of_ticket.keyword"
                            :react="{ and: getOtherFilterIds('typeOfTicketFilter') }"
                        />
                    </div>
                    </div>
                </div>
                </v-guard>
                <div class="col-lg-12 col-xl-9 order-lg-1 order-xl-1">
                    123
                <reactive-component
                    @queryChange="getQueryChange"
                    componentId="myColorPicker"
                    :URLParams="true"
                    :react="{ and: getOtherFilterIds('') }"
                    :defaultQuery="
                    () => ({
                        query: {
                            bool: {
                                must: [{
                                    range: {
                                        'year.raw': {
                                            gte: 2022
                                        },
                                    }
                                }],
                            },
                            
                        },
                        runtime_mappings: {
                            'date.month_of_years': {
                            type: 'long',
                            script: 'emit(doc[\'created_at\'].value.getMonthValue())'
                            }
                        },
                        aggs: {
                            year: {
                                terms: {
                                    field: 'year.raw',
                                    order: {
                                        _key: 'asc'
                                    },
                                    min_doc_count: 1,
                                    size: 100
                                }
                            },
                            crimes: {
                            terms: {
                                field: 'ad_crime.keyword',
                                order: {
                                _key: 'asc'
                                },
                                size: 100,
                                exclude : ''
                            },
                            aggs: {
                                year: {
                                terms: {
                                    field: 'year.raw',
                                    order: {
                                    _key: 'asc'
                                    },
                                    min_doc_count: 1,
                                    size: 100
                                }
                                }
                            }
                            },
                            office: {
                            terms: {
                                field: 'office.raw',
                                order: {
                                _key: 'asc'
                                },
                                size: 100
                            },
                            aggs: {
                                year: {
                                terms: {
                                    field: 'year.raw',
                                    order: {
                                    _key: 'asc'
                                    },
                                    min_doc_count: 1,
                                    size: 100
                                }
                                }
                            }
                            },
                            testimony_security_room: {
                            terms: {
                                field: 'testimony_security_room.raw',
                                order: {
                                _key: 'asc'
                                }
                            },
                            aggs: {
                                year: {
                                terms: {
                                    field: 'year.raw',
                                    order: {
                                    _key: 'asc'
                                    },
                                    min_doc_count: 1,
                                    size: 100
                                }
                                }
                            }
                            },
                            court_contact_type: {
                            terms: {
                                field: 'court_contact_type.raw',
                                order: {
                                _key: 'asc'
                                }
                            },
                            aggs: {
                                year: {
                                terms: {
                                    field: 'year.raw',
                                    order: {
                                    _key: 'asc'
                                    },
                                    min_doc_count: 1,
                                    size: 100
                                }
                                }
                            }
                            },
                            gender: {
                            terms: {
                                field: 'gender.raw',
                                order: {
                                _key: 'asc'
                                }
                            },
                            aggs: {
                                year: {
                                terms: {
                                    field: 'year.raw',
                                    order: {
                                    _key: 'asc'
                                    },
                                    min_doc_count: 1,
                                    size: 100
                                }
                                }
                            }
                            },
                            age: {
                            terms: {
                                field: 'age.raw',
                                order: {
                                _key: 'asc'
                                }
                            },
                            aggs: {
                                year: {
                                terms: {
                                    field: 'year.raw',
                                    order: {
                                    _key: 'asc'
                                    },
                                    min_doc_count: 1,
                                    size: 100
                                }
                                }
                            }
                            },
                            testimony_applicant: {
                            terms: {
                                field: 'testimony_applicant.raw',
                                order: {
                                _key: 'asc'
                                }
                            },
                            aggs: {
                                year: {
                                terms: {
                                    field: 'year.raw',
                                    order: {
                                    _key: 'asc'
                                    },
                                    min_doc_count: 1,
                                    size: 100
                                }
                                }
                            }
                            },
                            month: {
                            terms: {
                                field: 'date.month_of_years',
                                order: {
                                _key: 'asc'
                                },
                                size: 100
                            },
                            aggs: {
                                year: {
                                terms: {
                                    script: 'if (doc[\'created_at\'].size() != 0) doc[\'created_at\'].value.getYear()',
                                    order: {
                                    _key: 'asc'
                                    },
                                    min_doc_count: 1,
                                    size: 100
                                }
                                }
                            }
                            },
                            quarter: {
                            terms: {
                                field: 'quarter.raw',
                                order: {
                                _key: 'asc'
                                }
                            },
                            aggs: {
                                year: {
                                terms: {
                                    field: 'year.raw',
                                    order: {
                                    _key: 'asc'
                                    },
                                    min_doc_count: 1,
                                    size: 100
                                }
                                }
                            }
                            },
                            user: {
                            terms: {
                                field: 'user.raw',
                                order: {
                                _key: 'asc'
                                }
                            },
                            aggs: {
                                year: {
                                terms: {
                                    field: 'year.raw',
                                    order: {
                                    _key: 'asc'
                                    },
                                    min_doc_count: 1,
                                    size: 100
                                }
                                }
                            }
                            },
                            testimony_support: {
                            nested: {
                                path: 'testimony_support'
                            },
                            aggs: {
                                relation: {
                                terms: {
                                    field: 'testimony_support.label.raw',
                                    order: {
                                    _key: 'asc'
                                    },
                                    size: 100
                                },
                                aggs: {
                                    reverted: {
                                    reverse_nested: {},
                                    aggs: {
                                        year: {
                                        terms: {
                                            field: 'year.raw',
                                            order: {
                                            _key: 'asc'
                                            },
                                            min_doc_count: 1,
                                            size: 100
                                        }
                                        }
                                    }
                                    }
                                }
                                }
                            }
                            },
                            persons: {
                            nested: {
                                path: 'persons'
                            },
                                aggs: {
                                    reverted: {
                                    reverse_nested: {},
                                    aggs: {
                                        year: {
                                        terms: {
                                            field: 'year.raw',
                                            order: {
                                            _key: 'asc'
                                            },
                                            min_doc_count: 1,
                                            size: 100
                                        }
                                        }
                                    }
                                }
                            }
                            },
                            external_case: {
                            terms: {
                                field: 'external_case.raw',
                                order: {
                                _key: 'asc'
                                }
                            },
                            aggs: {
                                year: {
                                terms: {
                                    field: 'year.raw',
                                    order: {
                                    _key: 'asc'
                                    },
                                    min_doc_count: 1,
                                    size: 100
                                }
                                }
                            }
                            },
                            court: {
                            terms: {
                                field: 'court.raw',
                                order: {
                                    _key: 'asc'
                                },
                                size: 100,
                                exclude : ['']
                            },
                            aggs: {
                                year: {
                                terms: {
                                    field: 'year.raw',
                                    order: {
                                    _key: 'asc'
                                    },
                                    min_doc_count: 1,
                                    size: 100
                                }
                                }
                            }
                            },
                            testimony_other_type: {
                            terms: {
                                field: 'testimony_other_type.raw',
                                order: {
                                _key: 'asc'
                                }
                            },
                            aggs: {
                                year: {
                                terms: {
                                    field: 'year.raw',
                                    order: {
                                    _key: 'asc'
                                    },
                                    min_doc_count: 1,
                                    size: 1000000
                                }
                                }
                            }
                            },
                            testimony_type: {
                            terms: {
                                field: 'testimony_type.raw',
                                order: {
                                _key: 'asc'
                                }
                            },
                            aggs: {
                                year: {
                                terms: {
                                    field: 'year.raw',
                                    order: {
                                    _key: 'asc'
                                    },
                                    min_doc_count: 1,
                                    size: 100
                                }
                                }
                            }
                            },
                            crime_relation: {
                                nested: {
                                    path: 'crime_relation',
                                },
                                aggs: {
                                    relation: {
                                        terms: {
                                            field: 'crime_relation.label.raw',
                                            order: {
                                                _key: 'asc'
                                            },
                                            size: 100,

                                        },
                                        aggs: {
                                            reverted: {
                                                reverse_nested: {},
                                                aggs: {
                                                    year: {
                                                        terms: {
                                                            field: 'year.raw',
                                                            order: {
                                                                _key: 'asc'
                                                            },
                                                            min_doc_count: 1,
                                                            size: 100,
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                    },
                                },
                            },
                            special_event: {
                                terms: {
                                    field:  this.$field('special_event')+'.raw',
                                    order: {
                                        _key: 'asc'
                                    },
                                },
                                aggs: {
                                    year: {
                                        terms: {
                                            field: 'year.raw',
                                            order: {
                                                _key: 'asc'
                                            },
                                            min_doc_count: 1,
                                            size: 100,
                                        },
                                    },
                                },
                            },
                            category: {
                                terms: {
                                    field:  'ad_category.keyword',
                                    order: {
                                        _key: 'asc'
                                    },
                                },
                                aggs: {
                                    year: {
                                        terms: {
                                            field: 'year.raw',
                                            order: {
                                                _key: 'asc'
                                            },
                                            min_doc_count: 1,
                                            size: 100,
                                        },
                                    },
                                },
                            },
                            type_of_ticket: {
                                terms: {
                                    field:  'ad_type_of_ticket.keyword',
                                    order: {
                                        _key: 'asc'
                                    },
                                },
                                aggs: {
                                    year: {
                                        terms: {
                                            field: 'year.raw',
                                            order: {
                                                _key: 'asc'
                                            },
                                            min_doc_count: 1,
                                            size: 100,
                                        },
                                    },
                                },
                            },
                            testimony_support: {
                                nested: {
                                    path: 'testimony_support',
                                },
                                aggs: {
                                    relation: {
                                        terms: {
                                            field: 'testimony_support.label.raw',
                                            order: {
                                                _key: 'asc'
                                            },
                                            size: 100,

                                        },
                                        aggs: {
                                            reverted: {
                                                reverse_nested: {},
                                                aggs: {
                                                    year: {
                                                        terms: {
                                                            field: 'year.raw',
                                                            order: {
                                                                _key: 'asc'
                                                            },
                                                            min_doc_count: 1,
                                                            size: 100,
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                    },
                                },
                            },
                        }
                    })
                    "
                >
                    <div slot-scope="{ aggregations, hits, setQuery }">
                    <!-- {{aggregations}} -->
                    <div class="row">
                        <div class="col-12">
                            <statistic-widget-total :yearsSelected="yearsSelected" :data="aggregations.year.buckets" :title="$t('labels.total')"></statistic-widget-total>
                        </div>
                        <div class="col-12">
                            <statistic-widget :yearsSelected="yearsSelected" :yearsAggs="aggregations.year.buckets" :data="aggregations.quarter.buckets" :infoBox="''" :title="$t('labels.quarter')"></statistic-widget>
                        </div>
                        <div class="col-12">
                            <statistic-widget :yearsSelected="yearsSelected" :yearsAggs="aggregations.year.buckets" :data="aggregations.month.buckets" :infoBox="''" :title="$t('labels.month')"></statistic-widget>
                        </div>
                        <div class="col-12">
                            <statistic-widget :yearsSelected="yearsSelected" :yearsAggs="aggregations.year.buckets" :data="aggregations.gender.buckets" :infoBox="''" :title="$t('labels.gender')"></statistic-widget>
                        </div>
                        <div class="col-12">
                            <statistic-widget :yearsSelected="yearsSelected" :yearsAggs="aggregations.year.buckets" :data="customSortAge(aggregations.age.buckets)" :infoBox="''" :title="$t('labels.age-group')"></statistic-widget>
                        </div>
                        <div class="col-12">
                            <statistic-widget :yearsSelected="yearsSelected" :yearsAggs="aggregations.year.buckets" :data="aggregations.category.buckets" :infoBox="''" title="Kategori"></statistic-widget>
                        </div>
                        <div class="col-12">
                            <statistic-widget :yearsSelected="yearsSelected" :yearsAggs="aggregations.year.buckets" :data="aggregations.crimes.buckets" :infoBox="''" :title="$t('labels.crime')"></statistic-widget>
                        </div>
                        <div class="col-12">
                            <statistic-widget :yearsSelected="yearsSelected" :yearsAggs="aggregations.year.buckets" :data="aggregations.crime_relation.relation.buckets" :title="$t('labels.special-circumstances')">></statistic-widget>
                        </div>
                        <div class="col-12">
                            <statistic-widget :yearsSelected="yearsSelected" :yearsAggs="aggregations.year.buckets" :data="aggregations.special_event.buckets" :title="$t('labels.special-events')"></statistic-widget>
                        </div>
                        <div class="col-12">
                            <statistic-widget :yearsSelected="yearsSelected" :yearsAggs="aggregations.year.buckets" :data="aggregations.office.buckets" :infoBox="''" :title="$t('labels.victim-support-centre')"></statistic-widget>
                        </div>
                        <div class="col-12">
                            <statistic-widget :yearsSelected="yearsSelected" :yearsAggs="aggregations.year.buckets" :data="sortTestimonySupport(aggregations.testimony_support.relation.buckets)" title="Stödinsats"></statistic-widget>
                        </div>
                        <div class="col-12">
                            <statistic-widget :yearsSelected="yearsSelected" :yearsAggs="aggregations.year.buckets" :data="aggregations.court.buckets" :infoBox="''" title="Domstol"></statistic-widget>
                        </div>
                        <div class="col-12">
                            <statistic-widget :yearsSelected="yearsSelected" :yearsAggs="aggregations.year.buckets" :data="aggregations.type_of_ticket.buckets" :infoBox="''" title="Statistiktabell"></statistic-widget>
                        </div>
                    </div>
                    </div>
                </reactive-component>
                </div>
            </div>
            </reactive-base>

            <ScrollTopArrow />
        </div>

    </layout-main>
</template>

<script>
import BojAPI from '@/api/boj';
import _ from 'lodash';
import LayoutMain from '@/views/Layouts/Main';
import StatisticWidget from '@/components/widgets/office/StatisticWidget.vue';
import StatisticWidgetTotal from '@/components/widgets/StatisticWidgetTotal.vue';
import StatisticWidgetActionTime from '@/components/widgets/StatisticWidgetActionTime.vue';
import statisticWidgetHierarchical from '@/components/widgets/statisticWidgetHierarchical.vue';
import ExportTickets from '@/components/ticket/ExportTickets';
import InfoBox from '@/components/InfoBox';
import ScrollTopArrow from '@/components/ScrollToTop'

export default {
    components: {
        LayoutMain,
        StatisticWidget,
        StatisticWidgetTotal,
        StatisticWidgetActionTime,
        statisticWidgetHierarchical,
        InfoBox,
        ScrollTopArrow,
        ExportTickets
    },
    data() {
        return {
            elasticUrl: process.env.VUE_APP_API_ELASTIC,
            getSortBy: 'id',
            isLoading: false,
            allMonths: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'Maj',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Okt',
                'Nov',
                'Dec'
            ],
            customAge: [
                'Barn 0-11',
                'Ungdom 12-17',
                'Ung vuxen 18-25',
                'Vuxen 26-64',
                'Äldre 65 +',
                'Uppgift saknas'
            ],
            facets: [
                'yearFilter',
                'monthFilter',
                'quarterFilter',
                'courtContactTypeFilter',
                'testimonySecurityRoomFilter',
                'officeFilter',
                'crimesFilter',
                'testimonySupportFilter',
                'testimonyTypeSubFilter',
                'courtFilter',
                'userSubFilter',
                'externalCaseFilter',
                'genderFilter',
                'agesFilter',
                'testimonyApplicantFilter',
                'testimonySupportFilter',
                'civilCaseFilter',
                'specialEventFilter',
                'specialCircumstanceFilter',
                'categoryFilter',
                'typeOfTicketFilter',
                'testimony_support'
            ],
            yearsSelected: [],
            query: {},
            latestQuery: '',
            exportEstimate: false,
            isDownloading: false
        };
    },
    methods: {
        renameMonthsLabel(label) {
            label = this.allMonths[this.allMonths.indexOf(label.slice(0,3))] ?? '';

            return label;
        },
        sortMonthByLabel(data) {
            let months = data.slice().sort((a, b) => {
                return this.allMonths.indexOf(a.key.slice(0,3)) - this.allMonths.indexOf(b.key.slice(0,3));
            });

            return months;
        },
        customSortAge(data) {
            let newData = data.slice().sort((a, b) => {
                return this.customAge.indexOf(a.key) - this.customAge.indexOf(b.key);
            });

            return newData;
        },
        customYears(data) {
           return data.filter(e => e.key >= 2022);
        },
        
        sortTestimonySupport(data) {
            const originalData = this.$store.getters.testimonyOptions.testimony_support;
            const sortOrder = originalData.map(a => {
                return a.label;
            });

			let newData = data.slice().sort((a, b) => {
				return sortOrder.indexOf(a.key) - sortOrder.indexOf(b.key);
			});


			return newData;
		},
        getOtherFilterIds(id) {
            let ids = this.facets.filter((f) => f !== id).map((f) => f);

            return ids;
        },
        setSelectedYears(y) {
            if (y !== undefined && y !== null) {
                this.yearsSelected = y;
            } else {
                this.yearsSelected = [];
            }
        },
        /**
         * Toggle Export modal.
         */
        closeExportTickets() {
            this.exportEstimate = null;
        },
        /**
         * Store any changes to the Elasticsearch query
         */
        getQueryChange(prevQuery, nextQuery) {
            this.latestQuery = nextQuery;
        },

        /**
         * Retrieve an estimate before actually exporting tickets.
         */
        exportResultsEstimate(template) {
            this.isDownloading = true;
            BojAPI.exportCombinedTicketsFromElasticEstimate(this.latestQuery).then((response) => {
                this.exportEstimate = response.data;
                this.isDownloading = false;
            });
        },

        /**
         * Begin export of tickets.
         */
        exportResults(template) {
            this.exportEstimate = null;
                BojAPI.exportCombinedTicketsFromElastic(this.latestQuery).then((response) => {
            });
        },
    },
    computed: {
        user() {
            return this.$store.getters.user;
        },
    }
}
</script>

<style>

</style>
